html {
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Visby';
}

.mySwiper .swiper-pagination {
    top: 150px;
}

#button {
    position: fixed;
    bottom: 0;
    right: 0;
}

@font-face {
    font-family: 'Visby';
    src: local('Visby'), url(./../src/fonts/visby_round_cf_regular.otf) format('truetype');
}

@font-face {
    font-family: 'Visby Round CF Light';
    src: local('Visby Round CF Light'), url(./../src/fonts/visby_round_cf_light.otf) format('truetype');
}

.leaflet-container {
    height: 100% !important;
    width: 100% !important;
}
